<template>
  <div>
    <div class="pakettable" :class="duzlem">
      <div v-for="(paket, index) in gosterilecek_paketler.filter(e=>e.active)" :key="index">
        <div class="paketline leftside">
          <div class="title">Paket adı</div>
          <div class> İzin sayısı <sub>1</sub> </div>
          <div :class="detay_class" class> Çoklu izin girişi <sub>2</sub> </div>

          <div :class="detay_class" class> Web den tekil izin girişi <sub>3</sub> </div>
          <div :class="detay_class" class> API <sub>4</sub> </div>
          <div :class="detay_class" class> İleti Yönetim Sistemi üzerinden onay veya ret alma<sub>5</sub> </div>
          <div class> ÇM, kısa numara, kısa link <sub>6</sub> </div>
          <div class> Özel anahtar kelime <sub>7</sub> </div>
          <div :class="detay_class" class> Onay bilgilendirme SMS <sub>8</sub> </div>
          <div class> Alt kullanıcılar / marka bazlı yönetim<sub>9</sub> </div>
          <div :class="detay_class" class> Raporlama <sub>10</sub> </div>
          <div :class="detay_class" class> API üzerinden geri bildirim <sub>11</sub> </div>


          <div class="stickyline">
            <div class="yearly priceline"><div>Yıllık fiyat</div><span>(İndirimli)</span></div>
            <div v-if="hasbutton==false"></div>
          </div>
        </div>

        <div class="paketline pack" :class="[paket.attributes.css_class_name, {onerilen:paket.id==onerilen_id}]">
          <div class="title">{{paket.name}}</div>
          <div class="izinsayi">{{paket.attributes.max_number | toIntWithComma}}</div>

          <div :class="detay_class" >{{paket.attributes.bulk_entry | toIntWithComma}}</div>

          <div :class="detay_class" >            <img :src="paket.attributes.web_entry?require('@/assets/img/ico_tick_green.svg'):require('@/assets/img/ico_na.svg')" alt />          </div>
          <div :class="detay_class">            <img :src="paket.attributes.api_usage?require('@/assets/img/ico_tick_green.svg'):require('@/assets/img/ico_na.svg')" alt />          </div>
          <div :class="detay_class">            <img :src="paket.attributes.iys_notification?require('@/assets/img/ico_tick_green.svg'):require('@/assets/img/ico_na.svg')" alt />          </div>
          <div>            <img :src="paket.attributes.short_code_and_link?require('@/assets/img/ico_tick_green.svg'):require('@/assets/img/ico_na.svg')" alt />          </div>
          <div>            <img :src="paket.attributes.keyword_and_short_link?require('@/assets/img/ico_tick_green.svg'):require('@/assets/img/ico_na.svg')" alt />          </div>
          <div :class="detay_class" >            <img :src="paket.attributes.sms_notification?require('@/assets/img/ico_tick_green.svg'):require('@/assets/img/ico_na.svg')" alt />          </div>
          <div>            <img :src="paket.attributes.users_and_brands?require('@/assets/img/ico_tick_green.svg'):require('@/assets/img/ico_na.svg')" alt />          </div>
          <div :class="detay_class" >            <img :src="paket.attributes.reporting?require('@/assets/img/ico_tick_green.svg'):require('@/assets/img/ico_na.svg')" alt />          </div>
          <div :class="detay_class" >            <img :src="paket.attributes.api_notification?require('@/assets/img/ico_tick_green.svg'):require('@/assets/img/ico_na.svg')" alt />          </div>

          <div class="stickyline">
            <div class="priceline" v-if="paket.attributes.display_text">{{paket.attributes.display_text}}</div>
            <div v-if="!paket.attributes.display_text" class="yillik priceline">
              <div class="price">{{Math.ceil(paket.price_base)}} ₺</div>
              <div>{{Math.ceil(paket.price)}} ₺</div>
              </div>
            <div class="btnline" v-if="hasbutton==false" @click="$emit('onSecim', parseInt(paket.id))">
              <div class="btn">
                <a>{{paket.attributes.button_text}}</a>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>


<div class="dikey aciklamabox" :class="detay_class" >
        <div :class="detay_class" class="aciklama">
          <div class="line">(1) Bir yıl içinde saklanabilecek maksimum izinli veri adedini ve işlem sayısını gösterir.</div>
          <div class="line">(2) Çoklu veri girişi.</div>
          <div class="line">(3) API sağlama</div>
          <div class="line">(4) Ret işlemlerinde kullanılacak anahtar kelimeyi ifade eder. Örn: IPTAL ABCCICEK</div>
          <div class="line">(5) Verilerin İleti Yönetim Sistemi'ne yüklenmesi sonrasında alıcılara gönderilen onay alma talepleri ve ret bildirimlerinin takibi</div>
          <div class="line">(6) <s>Çağrı merkezi, kısa numara ve kısa link hizmetleri ayrı ayrı satırlarda yer alacak</s>.</div>
          <div class="line">(7) Şirket ve/veya alt markalarının isimlerinin onay ve ret bildirimlerinde kullanılabilmesi.</div>
          <div class="line">(8) Onay talebinin kabulü sonrası alıcılara bu yönde mesajla bildirim yapılması</div>
          <div class="line">(9) Oluşturulacak İleti Yönetim Sistemi hesabında farklı görevleri üstlenebilecek birden fazla kullanıcının tanımlanabilmesi ve şirketin alt markalarını ayrı ayrı yönetebilme</div>
          <div class="line">(10) Günlük onay ve ret bildirimlerini içerir raporların Hizmet Sağlayıcılara sunulması</div>
          <div class="line">(11) Onay ve ret bildirimleri ile kamu işlemlerinde gerekli bilgi ve belgelerin sağlanması ve sorgulanması entegrasyonu</div>
        </div>
</div>

    <div class="detaybtn" @click="detay_class=='short'?detay_class='long':detay_class='short'">
      <a v-if="detay_class=='short'">Detayları Gör</a>
      <a v-if="detay_class=='long'">Detayları Gizle</a>
    </div>

    <div class="tarih">
      Geçerlilik süresi:
      <span>1 YIL</span>
    </div>




  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name : "Paket",

  props: ["duzlem", "hasbutton", "paket_ids", "onerilen_id"],

  data () {
    return {
      detay_class : "short" // long
    }
  },

  computed: {
    ...mapState("purchase", ["packets"]),

    gosterilecek_paketler() {
      if (!this.packets) return [];
      if (typeof this.paket_ids != "undefined")
        return this.packets.filter(e => this.paket_ids.includes(e.id)) || [];
      else return this.packets || [];
    }
  },

  watch : {
    packets : {
      immediate : true,
      handler () {
        this.$store.dispatch("purchase/load_pakets")
      }
    }
  }
};
</script>

<style scoped>

*  {    transition: all 0.3s ease-out !important;}


.tekil .dikey.pakettable .paketline .title { top: 7vh !important; }

.detaybtn {
    display: flex;
    width: 13vw;
    height: 1.5vw;
    background: #eeeeee;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 0 0 5vw 5vw; cursor: pointer;}

.detaybtn:hover {background: #dedede;}

.dikey .short {
    height: 0vh !important;
    min-height: 0vh !important;
    border-bottom: 0 !important;
    border-top: 0 !important;
    overflow: hidden;
    margin: 0;
}

.dikey .priceline {height: 8vh; align-content: center !important; display: grid;}

.dikey .long {}

.dikey.pakettable .yearly > *  { align-self: flex-end; }


.aciklamabox {    background: #eeeeee;
    border-radius: 0.5vw;
    border-top: 1px dashed #dedede;
        display: flex;}

.aciklama {
  font-size: small;
  margin-bottom: 2vh;
  column-count: 2;
  margin: 2vw;
}

.aciklamabox.short { width: 96%; margin: 0 auto;}

.aciklama .line { margin: 0 1.1vh 1.1vh 0; }
.aciklama .line a { color: #4da0df; }
.aciklama .line a:hover { text-decoration: underline; }

.dikey.onerilen.pack {  border: 3px #4da0df solid;  transform: scale(1.05);}

.dikey.onerilen.pack .title::after {
  content: "önerilen";
  background: #4da0df;
  position: absolute;
  margin-top: 20%;
  padding: 0.5% 3%;
  border-radius: 0.5vh;
  color: #000;
}

.yatay .short {
    width:  0vh !important;
    min-width: 0vh !important;
    border-right: 0 !important;
    padding: 0 !important;
    line-height: 0 !important;
    overflow: hidden;
}

.yatay .short img {width: 0;}

.yatay.pakettable > div {
  display: contents;
}
.yatay.pakettable > div:first-child .leftside {
  display: flex !important;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
}

.yatay.pakettable {
  display: flex;
  background: #eeeeee;
  border-radius: 0.5vw;
  margin: 2vh 0 0 0;
  flex-direction: column;
  padding: 2%;
  width: 100%;
  overflow-x: overlay;
}
.yatay.pakettable .leftside {
  display: none;
}
.yatay.pakettable .paketline.pack {
  display: flex !important;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1vh;
  height: 10vh;
}
.yatay.pakettable .paketline .twoline {
  min-width: 26%;
  min-height: 11vh;
  border-right: none !important;
}
.yatay.pakettable .paketline .title {
  min-width: 22%;
  color: #fff;
  font-weight: 700;
}
.yatay.pakettable .leftside > div {
  text-align: center;
  justify-content: flex-end !important;
  align-items: flex-end;
  padding-bottom: 1vh !important;
  right: 0;
  flex-flow: column;
}
.yatay.pakettable sub {
  margin-bottom: -0.4vw;
}
.yatay.pakettable .izinsayi {
  font-weight: 600;
}
.yatay.pakettable .paketline {
  padding: 0;
  margin: 0;
}
.yatay.pakettable .paketline > div {
  line-height: 1.2;
  min-height: 8vh;
  /* min-height: 11vh; Bunu kapattım */
  border-bottom: 1px solid #d0cece;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1vh;
  align-items: center;
  min-width: 13%;
  width: 100%;
  border-right: 1px solid #d0cece;
  border-bottom: none !important;
}
.yatay.pakettable .paketline > div:last-of-type {
  border-bottom: none;
  border-right: 0 !important;
  border-left: 1px solid #d0cece;
}
.yatay.pakettable .paketline > div:last-of-type div {
  width: 90%;
}
.yatay.pakettable .yillik {
  color: #4da0df;
  font-weight: 700;
}
.yatay.pakettable .paketline:nth-child(1) .title {}

.yatay.pakettable .paketline.ucretsiz .title {
 background: linear-gradient(to right bottom, #af9fe3 0%, #3f2661 100%);
}
.yatay.pakettable .paketline.baslangic .title {
  background: linear-gradient(to right bottom, #9bdfef 0%, #264d61 100%);
}
.yatay.pakettable .paketline.giris .title {
  background: linear-gradient(to right bottom, #a3d2bc 0%, #1e6337 100%);
}
.yatay.pakettable .paketline.giris_plus .title {
  background: linear-gradient(to right bottom, #9fde7f 0%, #5d5426 100%);
}
.yatay.pakettable .paketline.standart .title {
  background: linear-gradient(to right bottom, #d4b55e 0%, #e48113 100%);
}
.yatay.pakettable .paketline.standart_plus .title {
  background: linear-gradient(to right bottom, #f39585 0%, #a53b05 100%);
}
.yatay.pakettable .paketline.profesyonel .title {
  background: linear-gradient(to right bottom, #da7a5d 0%, #ab0731 100%);
}
.yatay.pakettable .paketline.leftside .title {
  font-weight: 700;
  color: inherit;
}
.yatay.pakettable .pack {
  text-align: center;
  background: #fff;
  /* border-radius: 0.5vw; */
  /* overflow: hidden; */
}

.yatay.pakettable .pack > div { background: #fff;}

.yatay.pakettable .price  { line-height: 5vh; }

.yatay .leftside .title {background: #eee;  }
.yatay .title {position: sticky; left: -2vw; }
.yatay .leftside .yearly {background: #eee;  }
.yatay .stickyline { /*position: sticky;*/ right: -2vw !important; background: #eeeeee; }

.tarih {
  font-weight: 500;
  background: #fff;
  display: block;
  padding: 0.5vw;
  margin-top: 1vh;
  position: relative;
  width: max-content;
  border-radius: 0.5vw;
  align-self: flex-end;
  color: #4da0df;
}

/*  on tablets portrait */
@media (orientation: portrait) and (min-width: 501px) {
  .yatay.pakettable {
    border-radius: 2vw;
  }
  .yatay.pakettable .leftside {
  }
  .yatay.pakettable .paketline .title {
  }
  .yatay.pakettable .pack {
    border-radius: 1vw;
  }
  .yatay.pakettable .btn a {
    padding: 1vw;
  }
  .yatay.pakettable .paketline.pack {  }

  .yatay.pakettable .btn a {  }
  .yatay.pakettable .paketline > div:last-of-type div {
    height: 80%;
    width: 80%;
  }
}

/*  on big screens */
@media only screen and (min-height: 1000px) {
  .yatay.pakettable .paketline > div {
    min-height: 4.5vh;
  }
  .yatay.pakettable .paketline .twoline {
    min-height: 9vh;
  }
  .yatay.pakettable .paketline .title {
  }
  .yatay.pakettable .pack {
    width: 100%;
  }
  .yatay.pakettable .leftside > div {
    padding: 0.5vh 0;
  }
  .yatay.pakettable .izinsayi {  }
  .yatay.pakettable .leftside {
    width: 100% !important;
  }
  .yatay.pakettable .yillik {  }
  .tarih {  }

  .pakettable sub { margin-left: 0.2vw !important;  padding: 0.1vw !important;}
  .detaybtn { width: 9vw; height: 1vw;  border-radius: 0 0 3vw 3vw;}
  .pakettable .yearly span {}
}

/*  on phones */
@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .yatay.pakettable .leftside {
    display: flex;
  }
  .yatay.pakettable {
    flex-direction: row;
    flex-flow: wrap;
    border-radius: 2vw;
    padding: 2%;
    width: 100%;
    overflow: overlay;
  }
  .yatay.pakettable .pack {
    border-radius: 4vw;
    margin: 0.5% 1% 4% 1% !important;
  }
  .yatay.pakettable .paketline {
    padding: 0;
    margin: 0.5vw;
    flex-flow: column nowrap !important;
    width: 60% !important;
  }
  .yatay.pakettable .paketline > div {
    min-width: 100%;
    min-height: 7.5vh;
  }
  .yatay.pakettable .paketline .title {
    min-width: 100%;
    min-height: 11vh;
  }
  .yatay.pakettable .paketline > div:last-of-type {
    min-width: 100%;
  }
  .yatay.pakettable .paketline > div {
    border-right: none;
    border-bottom: 1px solid #d0cece !important;
  }
  .yatay.pakettable .leftside {
    width: 35% !important;
    padding: 0vh 0;
  }
  .yatay.pakettable .leftside > div {
    text-align: right;
    justify-content: center !important;
    align-items: flex-end;
    padding-right: 0.8vh;
    padding-bottom: 0vh;
  }
  .yatay.izinsayi {
    font-weight: 600;
  }
  .yatay.pakettable .paketline > div:last-of-type {
    padding: 2.5vw;
  }
  .yatay.onerilen {
    transform: none !important;
  }
  .yatay.onerilen.pack .title::after {
    margin-top: -10%;
  }
  .yatay.onerilen.pack {
    width: 70%;
  }
  .yatay.tarih {
    width: 100%;
    text-align: center;
  }
  .yatay.pakettable .paketline.pack {
    height: auto !important;
  }

.yatay.pakettable .short {height: 0 !important; min-height: 0 !important;}

  .tarih {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding: 1vh;
    border-radius: 2vw;
  }

  .tarih span {
    color: #55565e;
  }
}

/* +++++++++++++++++++++++++++++++ YATAY SONU ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.dikey.pakettable > div {
  display: contents;
}

.dikey.pakettable > div:first-child .leftside {
  display: block;
}
.dikey.pakettable .leftside {
  display: none;
}

.dikey.pakettable {
  display: flex;
  flex-direction: row;
  background: #eeeeee;
  border-radius: 0.5vw;
  padding: 1.2vh;
      margin: 2vh 0 0vh 0;
}
.dikey.pakettable .leftside > div {
  text-align: right;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: flex-end !important;
  position: relative;
  right: -5%;
}
.dikey.pakettable .paketline.leftside .title {
  align-items: flex-end;
  font-weight: 700;
  color: inherit;
      background: #eeeeee;
    z-index: 9999;
    margin-right: -1vw;
    padding-right: 1vw;

}

.dikey.pakettable .paketline:hover {  transform: scale(1.035); }
.dikey.pakettable .paketline.leftside:hover {  transform: scale(1);}
.dikey .pack {z-index: 99999;}

.dikey.pakettable .pack {
  text-align: center;
  width: 20%;
  background: #fff;
  border-radius: 0.6vw;
  /* overflow: hidden; */
}
.pakettable sub {
    color: #000000;
    background: #d8d8d8;
    border-radius: 0.4vw;
    margin-left: 0.5vw;
    line-height: 1vh;
    align-self: center;
    padding: 0.3vw;
}

.dikey.pakettable .onerilen.pack {
  border: 2px #4da0df solid;
  transform: scale(1.035);
  box-shadow: 0 2px 20px #00000040;
}
.dikey.pakettable .onerilen.pack .title::after {
  content: "önerilen";
  background: #4da0df;
  position: absolute;
  margin-top: 4vh;
  padding: 0.5% 3%;
  border-radius: 0.5vh;
  color: #000;
}
.dikey.pakettable .paketline {
  padding: 0;
  margin: 0.5vw;
}
.dikey.pakettable .paketline > div {
  line-height: 1.2;
  min-height: 5vh;
  border-bottom: 1px solid #d0cece;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dikey.pakettable .paketline .twoline {
  min-height: 10vh;
}
.dikey.pakettable .izinsayi {
  font-weight: 400;
}
.dikey.pakettable .yillik {
  flex-direction: column;
  color: #4da0df;
  font-weight: 700;
}

.dikey .stickyline { position: sticky !important; top: 19vh; z-index: 999; display: flex; flex-direction: column; padding: 0vw !important; }
.dikey .stickyline .priceline { border-bottom: 1px solid #d0cece; }
.dikey .stickyline .btnline { padding: 0.5vw 0.2vw;}
.dikey .stickyline .btnline a { padding: 0.5vw 0.2vw;}

.dikey.pakettable .yearly {flex-direction: column; }
.pakettable .yearly div {font-weight: 600; }
.pakettable .yearly span {  }


.pakettable .price {color: #a7a7a7;   background: url(/assets/img/price.svg) no-repeat; background-size: contain; background-position: center;}

.stickminmargin .dikey.pakettable .paketline .title {top: 7vh !important;}

.dikey.pakettable .paketline .title {
  height: 8vh;
  color: #fff;
  font-weight: 700;
  position: sticky;
    top: 13vh;
    border-radius: 0.5vw 0.5vw 0 0;
    z-index: 99999;
}
.dikey.pakettable .paketline > div:last-of-type {
  border-bottom: none;
  padding: 1.4vw 0.5vw 0.5vw 0.5vw;
}
.dikey.pakettable .paketline > div:last-of-type div {
  width: 100%; border: none;
}
.dikey.pakettable .paketline.leftside > div:last-of-type {
  padding: 0;
}
.dikey.pakettable .paketline:nth-child(1) .title {}

.dikey.pakettable .paketline.ucretsiz .title {
 background: linear-gradient(to right bottom, #af9fe3 0%, #3f2661 100%);
}
.dikey.pakettable .paketline.baslangic .title {
  background: linear-gradient(to right bottom, #9bdfef 0%, #264d61 100%);
}
.dikey.pakettable .paketline.giris .title {
  background: linear-gradient(to right bottom, #a3d2bc 0%, #1e6337 100%);
}
.dikey.pakettable .paketline.giris_plus .title {
  background: linear-gradient(to right bottom, #9fde7f 0%, #5d5426 100%);
}
.dikey.pakettable .paketline.standart .title {
  background: linear-gradient(to right bottom, #d4b55e 0%, #e48113 100%);
}
.dikey.pakettable .paketline.standart_plus .title {
  background: linear-gradient(to right bottom, #f39585 0%, #a53b05 100%);
}
.dikey.pakettable .paketline.profesyonel .title {
  background: linear-gradient(to right bottom, #da7a5d 0%, #ab0731 100%);
}
.dikey.pakettable .aciklama .line {
  font-size: small;
}

/*  on big screens */
@media only screen and (min-height: 1000px) {
  .dikey.pakettable .paketline > div { min-height: 4.5vh;  }
  .dikey.pakettable .paketline .title { height: 6vh; }
  .dikey.pakettable .pack { width: 40%; }
  .dikey.pakettable .leftside > div {  right: -1%; }
  .dikey.pakettable .izinsayi {  }
  .dikey.pakettable .leftside { width: 40%; }
  .dikey.pakettable .paketline { margin: 0.3vw; }
  .dikey.pakettable .yillik {  }
  .dikey.pakettable .onerilen.pack .title::after { margin-top: 3vh; }
  .detaybtn { width: 10.5vw; height: 1.2vw; }
}

/*  on tablets portrait */
@media (orientation: portrait) and (min-width: 501px) {
  .dikey.pakettable {
    border-radius: 2vw;
  }
  .dikey.pakettable .leftside {
    width: 25% !important;
  }
  .dikey.pakettable .paketline .title {
  }
  .dikey.pakettable .pack {
    border-radius: 1vw;
  }
  .dikey.pakettable .btn a {
    padding: 1vw;
  }
  .dikey.pakettable .onerilen.pack .title::after {
    margin-top: 30%;
    padding: 2% 9%;
    border-radius: 2vw;
  }
  .detaybtn {
    width: 20vw;
    height: 3.5vw;
}
.aciklamabox { border-radius: 2vw;}
}



/*  on phones */
@media (orientation: portrait) and (max-width: 500px) {
  .dikey.pakettable .leftside {
    display: block;
  }
  .dikey.pakettable {
    flex-direction: row;
    flex-flow: wrap;
    border-radius: 2vw;
    padding: 2%;
    width: 100%;
    overflow: overlay;
  }
  .dikey.pakettable .pack {
    border-radius: 4vw;
    margin: 0.5% 1% 4% 1% !important;
  }
  .dikey.pakettable .paketline {
    padding: 0;
    margin: 0.5vw;
    flex-flow: column nowrap !important;
    width: 60% !important;
  }
  .dikey.pakettable .paketline > div {
    min-width: 100%;
    min-height: 7.5vh;
  }
  .dikey.pakettable .paketline .title {
    min-width: 100%;
    min-height: 10vh;
    border-radius: 2vw 2vw 0 0;
  }
  .dikey.pakettable .paketline > div:last-of-type {
    min-width: 100%;
    border: none !important;
  }
  .dikey.pakettable .paketline > div {
    border-right: none;
    /* border-bottom: 1px solid #d0cece !important; */
  }
  .dikey.pakettable .leftside {
    width: 35% !important;
    padding: 0vh 0;
  }
  .dikey.pakettable .leftside > div {
    text-align: right;
    justify-content: flex-end  !important;
    align-items: center;
    padding-right: 0.8vh;
    padding-bottom: 0vh;
  }
  .dikey.izinsayi {
    font-weight: 600;
  }
  .dikey.pakettable .paketline > div:last-of-type {
    padding: 2.5vw;
  }
  .dikey.onerilen {
    transform: none !important;
  }
  .dikey.onerilen.pack .title::after {
    margin-top: -10%;
  }
  .dikey.onerilen.pack {
    width: 70%;
  }
  .dikey.tarih {
    width: 100%;
    text-align: center;
  }
  .dikey.pakettable .paketline.pack {
    height: auto !important;
  }


  .detaybtn { width: 45vw; height: 6.5vw; }
}
</style>
